/* ############################################################################# */
/* ### FORMS ################################################################## */
/* ############################################################################ */

.yform {
	display: block;
	width: 100%;
	margin: 0;
  padding: 0;
}

.yform fieldset {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 100%;
	height: auto;
	margin: 1em 0;
	padding: 1em;
	border: 1px solid lightgray;
	border-radius: 5px;
	box-shadow: 0px 2px 0 lightgrey;
	background-color: white;
}

.yform legend {
	float: none;
	padding: .5em 1em;
	background-color: $primary !important;
	color: #fff !important;
	border-radius: 5px;
	box-shadow: 0px 2px 0 lightgrey;
}

.yform select {
	/* width: calc(100% - 1.5rem);
	height: auto;
	max-width:calc(100% - 1.5rem); */
	width:100%;
	border-radius: 0.375em;
	align-items: center;
	border: 1px solid transparent;
	border-color: #dbdbdb;
	border-radius: 4px;
	box-shadow: none;
	display: inline-flex;
	font-size: 0.9rem;
	height: 2.5em;
	justify-content: flex-start;
	line-height: 1.5;
	padding-bottom: calc(0.5em - 1px);
	padding-left: calc(0.75em - 1px);
	padding-right: calc(0.75em - 1px);
	padding-top: calc(0.5em - 1px);
	position: relative;
	vertical-align: top;
}

input[type="checkbox"] {
	margin-right: 0.5rem;
}

/* Placeholder */
.yform ::-webkit-input-placeholder {
	color: gray;
}

.yform :-moz-placeholder {
	color: gray;
}

.yform ::-moz-placeholder {
	color: gray;
}

.yform :-ms-input-placeholder {
	color: gray;
}

.yform input:focus::-webkit-input-placeholder {
	color: lightgray;
}

.yform input:focus:-moz-placeholder {
	color: lightgray;
}

.yform input:focus::-moz-placeholder {
	color: lightgray;
}

.yform input:focus:-ms-input-placeholder {
	color: lightgray;
}

.yform .input-group {
	position: relative;
	display: table;
	border-collapse: separate;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.form-captcha .input-group-addon {
	display:inline-block;
	padding: 0;
}

.form-captcha input {
	display:inline-block;
	padding: 0;
	width: 8em !important;
	padding: 1em;
	margin: 0 10px;
}

.input-group-addon,
.input-group-btn {
	width: auto;
	white-space: nowrap;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

a.btn-default {
	text-decoration: none;
	font-size: .8em;
	font-style: italic;
}

.alert {
	display:inline-block;
	width: 100%;
	border-radius: 10px;
	background-color: red;
	color: white;
	padding: 10px;
	margin-bottom: 10px;
}