/* we can use here all scss vars from bulma.scss 

mobile: up to 768px
tablet: from 769px
desktop: from 1024px
widescreen: from 1216px
fullhd: from 1408px

*/

a.active {
  text-decoration: underline !important;
}

.hero .textbox {
  position:absolute;
  width:auto;
  max-width:50%;
  bottom:30px;
  right:0;
}

@media screen and (max-width: 512px) {
  .hero .textbox {
    width:100%;
    max-width:100%;
    bottom:0;
    right:0;
  }
}

.searchresult-link {
  text-decoration: none !important;
}

.column.left-padding-desktop {
  padding-left: 0.75rem !important;
}

.article-paper {
  padding-top:20px;
}

.is-secondary,
.has-text-secondary {
  color: $secondary;
}

.has-background-primary-verylight {
  background-color: $primary-verylight;
}

.has-background-primary-opacity {
  background-color: rgba(32, 71, 109,.8);
}



.has-rounded-borders {
  border-radius: 0.25rem !important;
}

.has-rounded-top-borders {
  border-radius: 0.25rem 0.25rem 0 0!important;
}

.is-size-0 {
  font-size: $size-0;
}

@media screen and (max-width: 769px) {
  .is-size-0 {
    font-size: $size-1;
  }
}

@media screen and (max-width: 512px) {
  .is-size-0 {
    font-size: $size-2;
  }
  .column.left-padding-desktop {
    padding-left: 0 !important;;
  }
}


.is-transparent {
  background-color: transparent !important;
}

.has-hoverfx {
  opacity: 1;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.has-hoverfx:hover {
  opacity: 0.8;
}

.has-textshadow {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

.has-boxshadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

path {
  fill: transparent;
}

.has-z-index-0 {
  z-index: 0 !important;
}

.has-z-index-1 {
  z-index: 1 !important;
}

.has-z-index-2 {
  z-index: 2 !important;
}

.has-z-index-3 {
  z-index: 3 !important;
}

.has-z-index-4 {
  z-index: 4 !important;
}

.has-z-index-5 {
  z-index: 5 !important;
}

.has-opacity-0 {
  opacity: 0 !important;
}

.has-opacity-1 {
  opacity: 0.1 !important;
}

.has-opacity-2 {
  opacity: 0.2 !important;
}

.has-opacity-3 {
  opacity: 0.3 !important;
}

.has-opacity-4 {
  opacity: 0.4 !important;
}

.has-opacity-5 {
  opacity: 0.5 !important;
}

.has-opacity-6 {
  opacity: 0.6 !important;
}

.has-opacity-7 {
  opacity: 0.7 !important;
}

.has-opacity-8 {
  opacity: 0.8 !important;
}

.has-opacity-9 {
  opacity: 0.9 !important;
}

.has-opacity-10 {
  opacity: 1 !important;
}

.has-backdrop-gfx::after {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 100%;
  position: absolute;
  background-color: $secondary;
  bottom: -50px;
  right: -50px;
  z-index: 0;
  opacity: 0.2;
}

@media screen and (max-width: 512px) {
  .has-backdrop-gfx::after {
    width: 102%;
    height: 102%;
    bottom: -20px;
    right: -20px;
  }
}

.has-faded-background {
  background: linear-gradient($primary-verylight, white 150px);
}

.has-white-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0) 66%
  );
}

.has-background-image {
  background-size: cover;
  background-position: center center;
}

.has-dark-opacity-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navbar-item img {
  max-height: 100% !important;
}

.section-header {
  margin-bottom: 2rem;
}


aside.sidebar-widget {
  border-bottom: 5px solid $primary-dark;
}

aside.sub-navigation {
  position:relative;
  top:-50px;
  margin-bottom:-50px;
}

.widget-title {
  display:block;
  width:100%;
  padding: 0 .5rem;
  line-height:2.5rem;
  border-bottom: 5px solid $primary-dark;
}

.sub-navigation ul {
  width:100%;
  list-style-type: none;
}

.sub-navigation ul li {
  width:100%;
  border-top: 1px solid $primary-light;
  border-bottom: 1px solid $primary-dark;
  padding: 0 .5rem;
  line-height:2.5rem;
  color: white;
  transition: all .2s ease-out;
}

.sub-navigation ul li a {
  width:100%;
  height:100%;
  display:block;
  text-decoration: none;
  color: white;
}

.sub-navigation ul li:hover {
  background-color: $primary-light;
}
