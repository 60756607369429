@charset "utf-8";

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/merriweather-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/merriweather-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v30-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/merriweather-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/merriweather-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v30-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

// Set your brand colors
$scheme-main: #ffffff;
$scheme-invert: #152E47;
$widescreen-enabled: true; // no widescreen support
$fullhd-enabled: true; // fullHD support

// Update Bulma's global variables
$family-sans-serif: "Merriweather", serif;
$primary: #2f69a4;
$secondary: #ffcc00;
$primary-lightdark: darken($primary, 5%);
$primary-dark: darken($primary, 10%);
$primary-darker: darken($primary, 20%);
$primary-minlight: lighten($primary, 3%);
$primary-light: lighten($primary, 5%);
$primary-verylight: lighten($primary, 52%);
// https://falkus.co/2017/05/using-lighten-and-darken-in-sass/
$primary-hover: darken($primary, 2%);
$white: #ffffff;
$black: #000000;
$dark: #191919;
$gray: #888888;
$light: #f3f3f3;
$link: $primary-dark;
$button-focus-border-color: $primary;
$button-border-width: 1px;
$link-active: $primary-dark;
$weight-bold: 700;

// Update some of Bulma's component variables

// Body
$body-font-size: 1em;

html {
  background: $primary-darker !important;
}
body {
  background: linear-gradient($primary-minlight, $primary-darker 150px);
}

.splide__pagination li + li,
.splide__list li + li {
  margin: 0 !important;; 
}

// Font sizes
$size-0: 5rem;
$size-1: 2.3rem;
$size-2: 1.7rem;
$size-3: 1.3rem;
$size-4: 1.1rem;
$size-5: 1rem;
$size-6: 0.9rem;
$size-7: 0.75rem;

$content-blockquote-background-color:transparent;
$content-blockquote-border-left: 0px;
$content-blockquote-padding: 1rem 0;
blockquote {
  font-size: $size-4;
  color: $gray;
}

$body-font-size: 1em;

// Navbar
$navbar-background-color: transparent;
$navbar-height: 5rem;
$navbar-item-hover-background-color: $primary-darker;
.navbar-item:hover .navbar-item {
  /* keep bg color */
  background-color: $primary-darker;
}
$navbar-burger-color: white;
$navbar-item-color: white;
$navbar-item-hover-color: $navbar-item-color;

.navbar-dropdown {
  padding:5px !important;
  border-top-right-radius: 6px;
}
.navbar-dropdown a.navbar-item:hover {
  background-color: $primary-light !important;
  color: white !important;
}


$navbar-item-active-background-color: $primary-darker;
$navbar-burger-color: $navbar-item-color;
$navbar-dropdown-background-color: $primary-darker;
$navbar-dropdown-border-top: 0px solid $white;
/* 
$navbar-box-shadow-size: 0 0 0 0;
$navbar-box-shadow-color: $dark;
$navbar-item-active-color: $primary;
$navbar-dropdown-border-top: 2px solid $white;
$navbar-dropdown-offset: -4px;
$navbar-dropdown-arrow: $navbar-item-color; */

// Card
$card-background-color: $white;
$card-header-background-color: $primary;
$card-header-color: $scheme-main;
$card-header-padding: 0.75rem 1rem;
$card-shadow: 0 0.5em 1em -0.125em rgba($dark, 0.1),
  0 0px 0 1px rgba($dark, 0.02);
$card-header-shadow: 0 0.125em 0.25em rgba($scheme-invert, 0.1);
$card-header-weight: $weight-bold;
$card-radius: 0.25rem;

// Footer
$footer-background-color: transparent;
$footer-color: false;
$footer-padding: 2rem 1.5rem;



// Import only what you need from Bulma
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/elements/_all.sass";
@import "../../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../../node_modules/bulma/sass/elements/container.sass";
@import "../../../node_modules/bulma/sass/elements/title.sass";
@import "../../../node_modules/bulma/sass/form/_all.sass";
@import "../../../node_modules/bulma/sass/components/navbar.sass";
@import "../../../node_modules/bulma/sass/components/card.sass";
@import "../../../node_modules/bulma/sass/components/menu.sass";
@import "../../../node_modules/bulma/sass/layout/hero.sass";
@import "../../../node_modules/bulma/sass/layout/section.sass";
@import "../../../node_modules/bulma/sass/layout/footer.sass";
@import "../../../node_modules/bulma/sass/grid/columns.sass";

// Import all other used scss
@import "./extensions/_all";

/* .column {
  display: flex;
} */

.column .card {
  height:100%;
}