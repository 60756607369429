.table_wrap {
  width: 100%;
  max-width: 100%;
  overflow:auto;
}

td.primary-cell,
td.primary-cell * {
  color: #fff !important;
  background-color: $primary !important;
}

td.primarylight-cell,
td.primarylight-cell * {
  color: $primary !important;
  background-color: $primary-verylight !important;
}